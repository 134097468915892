import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  render() {
    return (
      <div
        className={this.state.hasScrolled ? "Header HeaderScrolled" : "Header"}
      >
        <div className="HeaderGroup">
          <Link to="/">
            <div className="Logo">
              <img src={require("../images/logo.png")} width="30" />
              <span>SlideTheory</span>
            </div>
          </Link>
          <Link to="/starter">Starters</Link>
          <Link to="/library">Library</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/buy">
            <button>Subscribe</button>
          </Link>
        </div>
      </div>
    )
  }
}

export default Header
